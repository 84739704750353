<script setup>
    // import { getOrCreateProcedure, getOrCreateDataObject } from 'o365.vue.ts';
    import { ref, watch } from 'vue';
    import IFrame from 'o365.vue.components.Iframe.vue';
    import { getOrCreateProcedure } from 'o365-modules';
    import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
    import { context } from 'o365-modules';

    /* VARIABLES */
    const procGetBOJson = getOrCreateProcedure({ id: "procGetBOJson", procedureName: "astp_Lease_GetRoomsBIMObjectsJson" });
    const vIframe = ref(null);
    const vLoaded = ref(false);
    const vGroupColors = ref(null);
    const props = defineProps({ vModelID: Number,
                                vFieldName: {type: String, default: null},
                                vFilter: String,
                                vSelectedObjectIDs: {type: String, default: '[]'},
                                vVisMode: {type: String, default: 'default'},
                                vMapMode: {type: Boolean, default: false},
                                vDS: Object
    });
    const vSelectedRows = ref([]);
    const vSelectingFromBIM = ref(false);
    const emit = defineEmits(['groupColorsAdded'])
    let vJsonData = null;
    let vFragments = null;
    let vIfcObjectIDs = null;
    window["vFragments"] = vFragments;
    window["vIfcObjectIDs"] = vIfcObjectIDs;

    window.addEventListener('message', (pObj)=>{
        if(pObj.data.event == "onReady") {
            vLoaded.value = true;
            loadBimData();
        }
        if(pObj.data.event == "onSelectedObjects") {
            console.log("onSelectedObjects");
            console.log(pObj.data.content);
            setSelectedObjects(pObj.data.content.object_ids);
        }
    });

    async function setSelectedObjects(pObjectIDs) {
        vSelectingFromBIM.value = true;
        await props.vDS.data.map(function(pRow) { 
            // Select object
            if(pObjectIDs.indexOf(pRow.ID) > -1) {
                if(!pRow.state.isSelected) {
                    console.log("Add: " + pRow.ID);
                    props.vDS?.selectionControl.selectRange(true, pRow.index, pRow.index);
                    vSelectedRows.value.push({ID: pRow.ID, SelectedFromBIM: true});
                }
            } else if(pRow.state.isSelected) { // Remove select
                console.log("Remove: " + pRow.ID);
                props.vDS?.selectionControl.selectRange(false, pRow.index, pRow.index);
                vSelectedRows.value = vSelectedRows.value.filter(pItem => pItem.ID !== pRow.ID);
            }
        });
        vSelectingFromBIM.value = false;
    };

    /* WATCH */
    watch(() => props.vModelID, (first, second) => {
        loadBimData();
    });

    watch(() => props.vFieldName, (first, second) => {
        loadBimData();
    });

    watch(() => props.vFilter, (first, second) => {
        loadBimData();
    });

    watch(() => props.vSelectedObjectIDs, (first, second) => {
        window["vSelectedObjectIDs"] = props.vSelectedObjectIDs;
        if(!vSelectingFromBIM.value) {
            vSelectedRows.value = JSON.parse(props.vSelectedObjectIDs);
            loadBimData();
        }
    });

    watch(() => props.vVisMode, (first, second) => {
        loadBimData();
    });

    watch(() => props.vMapMode, (first, second) => {
        loadBimData();
    });

    /* FUNCTIONS */
    function transformJson(objectsArr) {
        const floors = {};  
        vGroupColors.value = null;
        objectsArr.forEach((obj) => {
            const { Name : name, BIMObject_ID : bimObjectId, IFCFile_ID : ifcFileId, R : red, G : green, B : blue } = obj;
            if(!floors[name]) {
                floors[name] = {name: name, color: { r: red, g: green, b: blue }, objects: {}};
                vGroupColors.value = (vGroupColors.value ? vGroupColors.value + " | " : "") + "<div class='ml-2 d-inline-block'>" + name + "<div class='d-inline-block' style='background-color: rgb(" + red + "," + green + "," + blue + "); width: 11px; height: 11px;margin-left:2px;'></div></div>";
            }
            floors[name].objects[ifcFileId] ? floors[name].objects[ifcFileId].push(bimObjectId) : floors[name].objects[ifcFileId] = [ bimObjectId ];
        });
        return Object.values(floors);
    }

    async function loadBimData() {
        if(props.vDS?.state.isLoading) { 
            setTimeout(function() {
                loadBimData();
            }, 200);
        } else {
            if(vLoaded.value && props.vModelID) {
                const vData = await procGetBOJson.execute({
                                                            FieldName: props.vFieldName, 
                                                            Filter: props.vFilter,
                                                            Model_ID: props.vModelID, 
                                                            SpecifiedObjectJson: vSelectedRows.value ? JSON.stringify(vSelectedRows.value) : '[]'
                                                        });
                if(vData.Table.length > 0) {
                    vJsonData = JSON.parse(vData.Table[0]['JsonData']);
                    vFragments = [...new Set(vJsonData.map(pRow => pRow.Fragment_ID))];
                    vIfcObjectIDs = vJsonData ? transformJson(vJsonData) : null;
                    console.log(vFragments);
                    console.log(vIfcObjectIDs);
                    emit('groupColorsAdded', vGroupColors.value); 
                    
                    vIframe.value.iframeRef.contentWindow.postMessage({
                        origin: vIframe.value.iframeRef.contentWindow.location.origin, 
                        channel_id: 'room_register',
                        event: 'loadViewerStateRequest', 
                        content: {
                                        fragments: vFragments,
                                        ifcobject_ids: vIfcObjectIDs,
                                        rule: {
                                            hardSelect: true,
                                            isolate: props.vVisMode == 'isolated' ? true : false,
                                            base_color: true,
                                            map_mode: props.vMapMode
                                        },
                                        state: '3D'
                                    }
                    });
                }
            }
        }
    }
</script>

<template>
    <IFrame :src="'/bimviewer-rooms-fcm?HideNav=true&Model='+props.vModelID" ref="vIframe" style="height:100%;width:100%;"/> 
</template>